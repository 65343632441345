import { get } from 'lodash'
import * as authService from './auth'

export class APIError extends Error {
  constructor (apiMessage, ...params) {
    super(...params)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError)
    }

    this.errorDetails = apiMessage
  }
}

/**
 * This will attempt to find an "error" or "message" on the error object returned from the API
 * @param  {Object} jsonError      The object containing an error or message
 * @param  {String} [defaultError] The default error message
 * @return {String}                The message derived, or else a generic one.
 */
export const getAPIError = (jsonError, defaultError) => {
  if (typeof jsonError !== 'object') {
    return (
      defaultError || 'No error message provided, use options.defaultError or correct the API call'
    )
  }
  return (
    get(jsonError, 'error.error') ||
    get(jsonError, 'error.message') ||
    get(jsonError, 'error') ||
    get(jsonError, 'message') ||
    defaultError ||
    'No error message provided, use options.defaultError or correct the API call'
  )
}

const formatApiResponse = str => {
  try {
    return JSON.parse(str)
  } catch (err) {
    // failure to parse = return what you were given
    return str
  }
}

/**
 * Gateway Fetch Service
 * Utilizes the fetch API to perform requests
 *
 * @constructor
 * @param {object} action
 * @param {string} action.endpoint The API endpoint
 * @param {object} action.options The fetch API options
 * @param {string} action.defaultError Optional, the default error message to supply when an API error call has a JSON response.
 */
export const gatewayFetch = async action => {
  if (action && action.endpoint) {
    console.log('ROMANO - gatewayFetch - action ', action)
    console.log('ROMANO - gatewayFetch - action.endpoint ', action.endpoint)
    console.log('ROMANO - gatewayFetch - action.options ', action.options)
  }
  if (!action || typeof action !== 'object') {
    throw Error('gateway: Bad action object')
  }

  if (!action.options) {
    action.options = {}
  }

  if (!action.options.headers) {
    action.options.headers = new Headers({
      'Content-Type': 'application/json'
    })
  }

  // Set the headers for authorized calls
  if (!action.options.unauthorized) {
    console.log('ROMANO - unauthorized attempting to get new token from localstorage')
    const token = authService.getToken()
    console.log('ROMANO - new token of ', token)
    action.options.headers.append('Authorization', 'Bearer ' + token)
    console.log('ROMANO - unauthorized set new auth bearer token in headers ', action.options.headers)
  }

  try {
    let response = await fetch(action.endpoint, action.options)
    if (action) {
      if (action.endpoint) {
        console.log('ROMANO - gateway-fetch action.endpoint ', action.endpoint)
      }
      if (action.options) {
        console.log('ROMANO - gateway-fetch action.options ', action.options)
      }

      console.log('ROMANO - gateway-fetch response ', response)
    }
    let respText
    if (!response.ok) {
      try {
        respText = await response.text()
        let jsonError = JSON.parse(respText)
        throw new APIError(jsonError, getAPIError(jsonError, respText))
      } catch (err) {
        console.error('ROMANO - gatewayFetch err ', err)
        if (err instanceof Error && !err.message.includes('Unexpected token')) {
          throw err
        } else {
          // we do this because we can't attach the payload error data
          // to an Error object.
          throw Error(respText)
        }
      }
    }

    try {
      respText = await response.text()
      if (!respText || !respText.length) return null
      return formatApiResponse(respText)
    } catch (err) {
      throw Error(`Check network logs: ${err}`)
    }
  } catch (err) {
    throw err
  }
}

export default gatewayFetch

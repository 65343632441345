/*
 * Private Route handles checking authentication
 * and then display the component or go to Login
 */

// eslint-disable-next-line no-unused-vars
import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'

import * as AuthService from 'services/auth'

import Eula from 'components/common/eula'

import gateway from 'services/gateway'
import log from 'services/logger'

class Auth extends Component {
  constructor (props) {
    super(props)

    this.intervalId = null

    this.callback = this.callback.bind(this)
  }

  async componentDidMount () {
    console.log('ROMANO - TEST - component did mount triggered')
    const { authActions, notifyActions } = this.props
    this.intervalId = null

    AuthService.lock.on('authenticated', async authResult => {
      console.log('ROMANO - component did mount - authenticated event received autResult ', authResult)
      AuthService.setToken(authResult.idToken)
      AuthService.setAccessToken(authResult.accessToken)
      try {
        this.handleLogin()
      } catch (err) {
        console.error('ROMANO - handleLogin error of ', err)
        AuthService.setToken(null)
        AuthService.setAccessToken(null)
        const errorMsg = `Unable to login because of an error accessing user profile`
        authActions.loginError(new Error(errorMsg))
        notifyActions.addNotification({
          message: errorMsg,
          sticky: true
        })
      }
    })

    // Handle auth0 lifecycle event errors
    AuthService.lock.on('authorization_error', error => {
      console.error('ROMANO - TEST - authorization_error triggered with ', error)
      const errorType = error.error
      let errorMsgForDisplay = 'An error occurred. Please contact support for assistance.'
      // https://auth0.com/docs/libraries/common-auth0-library-authentication-errors
      const commonLoginErrorTypes = [
        'access_denied', // From Auth0 "user-state-check" action. Has error.errorDescription
        'unauthorized', // From Auth0 "user-state-check" rule. Has error.errorDescription
        'invalid_user_password', // only error.description, no error.errorDescription
        'PasswordHistoryError',
        'PasswordStrengthError',
        'too_many_attempts'
      ]

      if (commonLoginErrorTypes.indexOf(errorType) > -1) {
        if (error.errorDescription || error.description) {
          errorMsgForDisplay = error.errorDescription || error.description
        }
      } else if (errorType === 'invalid_request') {
        // apparently this error type required a more user-friendly message
        errorMsgForDisplay = 'The login request was invalid. Your browser may be configured to ' +
          'block Third Party Cookies. Please contact support for assistance.'
      }

      notifyActions.addNotification({ message: errorMsgForDisplay })
    })

    
    AuthService.lock.on('unrecoverable_error', error => {
      // According to Lock API reference:
      // emitted when there is an unrecoverable error, for instance when no connection is available.
      const defaultErrMessage = 'An error occurred. Please contact support for assistance.'

      notifyActions.addNotification({
        message: error.errorDescription || error.description || defaultErrMessage
      })
    })

    if (!AuthService.loggedIn()) {
      try {
        await AuthService.reAuthenticate()
        this.handleLogin()
      } catch (err) {
        // user is probably not logged in anywhere
        console.error('ROMANO - Not logged in caught error of ', err)
      }
    }

    // start setInterval to check for valid jwt
    this.start()
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.auth.isAuthenticated && this.props.auth.isAuthenticated) {
      Sentry.configureScope(scope => {
        scope.setUser(AuthService.getProfileForSave())
      })
      this.start()
    } else if (prevProps.auth.isAuthenticated && !this.props.auth.isAuthenticated) {
      this.stop()
    }
  }

  stop () {
    clearTimeout(this.intervalId)
    this.intervalId = null
  }

  start () {
    this.stop()
    this.intervalId = setTimeout(this.callback, 2000)
  }

  callback () {
    if (this.intervalId) {
      this.isJWTExpired()
      this.start()
    } else {
      this.stop()
    }
  }

  componentWillUnmount () {
    // remove setInterval
    this.stop()
  }

  async handleLogin () {
    console.log('ROMANO - TEST - handleLogin triggered...')
    const { authActions, history } = this.props

    console.log('ROMANO - TEST - making request to api/auth/userinfo')
    let profile = await gateway.get(`/api/auth/userinfo`)
    console.log('ROMANO - TEST - awaited userInfo request - recevied profile ', profile)
    AuthService.setProfile(profile)
    console.log('ROMANO - TEST - setProfile complete')
    authActions.loginSuccess(profile)
    console.log('ROMANO - TEST - beyond loginSuccess')
    if (window.location.pathname === '/' || window.location.pathname === '') {
      let redirect = AuthService.getRedirect()
      history.push(redirect || '/dashboard')
      AuthService.removeRedirect()
      AuthService.hide()
    } else {
      console.error('ROMANO - test else condition in handleLogin pathname is ', window.location.pathname)
    }
  }

  handleLogout () {
    this.props.notifyActions.addNotification({
      message: 'You were logged out because your authentication token expired',
      sticky: true
    })

    this.stop()

    AuthService.setRedirect(window.location.pathname)
    this.props.authActions.logoutSuccess()
    AuthService.logout(true)
    this.props.history.push({ pathname: '/' })
  }

  isJWTExpired = async () => {
    // if jwt is expired then loggout and force update this component
    const notAuthenticated = !AuthService.loggedIn()
    if (notAuthenticated) {
      if (window.location.pathname.includes('dashboard')) {
        log('token is missing', new Error('token is missing'), true)
        return this.handleLogout()
      }
      return
    }
    const expired = AuthService.isTokenExpired()
    const expiresSoon = AuthService.expiringSoon()
    if (AuthService.getToken() && expired) {
      log('token expired', new Error('token expired, inducing logout'), true)
      this.handleLogout()
    } else if (AuthService.getToken() && expiresSoon) {
      try {
        await AuthService.reAuthenticate()
        this.handleLogin()
      } catch (err) {
        log('token expired', err, true)
        this.handleLogout()
      }
    } else if (AuthService.getToken()) {
      if (Object.keys(AuthService.getProfile()).length === 0) {
        let profile = await gateway.get(`/api/auth/userinfo`)
        console.log('ROMANO - PROFILE RETURNED as part of isJWTExpired - ', profile)
        AuthService.setProfile(profile)
      }
    }
  }

  render () {
    const { auth } = this.props

    return auth.isAuthenticated && <Eula />
  }
}

export default Auth
